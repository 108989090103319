.profileGradient {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 32px;
}

.reviewButton {
    margin-top: 12px !important;
}

.friends-formItem-search {
    background-color: var(--vkui--color_background_modal);
    position: sticky;
    top: 0;
    z-index: 10;
}

.loadingSpinner {
    margin-top: 10px;
}

.MuiRating-iconEmpty {
    color: var(--vkui--color_text_secondary) !important;
}